<template>
    <div class="page-header">
        <h1>Bảng danh sách nhân viên</h1>
        <hr class="my-4">
    </div>
</template>

<script>
export default {
    name: 'comp-title',
    data() {
        return {

        }
    }
}
</script>

<style>

</style>
